require('../modules/es7.object.define-getter');
require('../modules/es7.object.define-setter');
require('../modules/es7.object.lookup-getter');
require('../modules/es7.object.lookup-setter');
require('../modules/es7.object.values');
require('../modules/es7.object.entries');
require('../modules/es7.object.get-own-property-descriptors');
require('../modules/es7.array.includes');
require('../modules/es7.string.pad-start');
require('../modules/es7.string.pad-end');
module.exports = require('../modules/_core');